<template>
  <div>
    <!--达标播主[start]-->
    <div class="panel2">
      <a-page-header title="达标播主" style="margin: 0 -24px 0; border-bottom: 1px solid #ebedf0;" />
<!--      <div class="panel-header">-->
<!--        <div class="panel-title">-->
<!--          <span>达标播主</span>-->
<!--        </div>-->
<!--        <a-page-header title="评论归集" style="margin: 0 -24px 0;" />-->
<!--      </div>-->
      <!--panel body[start]-->
      <div class="panel-body">
        <a-row type="flex">
          <a-radio-group v-model="sortBy" @change="search">
            <a-radio-button v-for="item in sortBys" :key="item[1]" :value="item[1]">{{item[0]}}</a-radio-button>
          </a-radio-group>
          <day-span v-model="daySpan" label="发布时间" @change="search" style="padding: 0 20px"></day-span>
        </a-row>
        <a-form-model layout="inline" ref="quotaSearchForm" style="margin-top:10px">
          <a-form-model-item label="达标下限">
            <a-input-number v-model="minNum[sortBy]" :min="0" @change="typeof minNum[sortBy] == 'number' || (minNum[sortBy] = 0)" style="width:120px" placeholder="未设置" />
          </a-form-model-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="search">查询</a-button>
          </a-form-item>
        </a-form-model>
        <a-row type="flex" :gutter="20">
          <a-col flex="auto">
            <v-table class="table-border-none table-list0"
              row-key="authorId" ref="authorTable" size="small"
              :columns="columns" :data="data" :pagable="false" :loading="loading"
              :scroll="{x:'auto', y:500}" :enableSelection="true" :showSelectionRadio="false"
              :autoSelect="true"
              @rowselected="onRowSelected"
              @rowdeselect="onRowDeselect">
              <template #orderNum="{$index}">{{$index+1}}</template>
              <template #code="{row}">
                <author-card :value="row.author" :dataType="$root.dataType" small></author-card>
              </template>
              <template #actions="{row}">
                <a-icon type="right" class="row-action" />
              </template>
            </v-table>
          </a-col>
          <a-col flex="400px">
            <qualified-aweme ref="qualifiedAweme" :sort-by="sortBy" :day-query="dayQuery" :minNum="minNum[sortBy]"></qualified-aweme>
          </a-col>
        </a-row>
      </div>
      <!--panel-body[end]-->
    </div>
    <!--达标播主[end]-->
  </div>
</template>
<style lang="less" >
.table-list0 {
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: unset !important;
    //height: 500px;
  }
  .row-action {
    display: none;
  }
  .ant-table-tbody > tr.ant-table-row-selected td {
    background-color: rgb(244, 255, 237);

    .row-action {
      display: inline;
    }
  }
}
</style>
<script>
import DaySpan from "@/components/widgets/day-span"
import AuthorCard from "@/components/widgets/author-card"
import QualifiedAweme from "./QualifiedAweme"

const defaultQuery = {
  orderBys: 'qualifieCount|desc',
  dayOffsetField: 'publishTime',
  dayOffset: undefined,
  dateRanges: {
    publishTime: {
      startDate: undefined,
      endDate: undefined
    }
  },
  param: {
    authorIds: undefined
  },
  groupBy: 'authorId',
  start: 0,
  limit: 1000
}
export default {
  components: { DaySpan, AuthorCard, QualifiedAweme },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      sortBy: "diggCount",
      sortBys: [
        ["播放", "playVolume"],
        ["点赞", "diggCount"],
        ["评论", "commentCount"],
        ["转发", "shareCount"]
      ],
      daySpan: {
        dayOffset: 7,
        dayRange: []
      },
      minNum: {
        playVolume: 1000,
        diggCount: 100,
        commentCount: 50,
        shareCount: 50
      },
      data: []
    };
  },
  computed: {
    columns() {
      return [
        { title: "排序", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: "code", scopedSlots: { customRender: "code" } },
        { title: "达标作品数", dataIndex: "qualifieCount", customRender: this.$dict.humanReadable, width: 100 },
        { title: "", dataIndex: "id", scopedSlots: { customRender: "actions" }, width: 60 }
      ]
    },
    dayQuery() {
      return {
        dateRanges: this.daySpan.dayRange.length ? {
          publishTime: {
            startDate: this.daySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.daySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.daySpan.dayRange.length ? undefined : (this.daySpan.dayOffset || 7)
      }
    }
  },
  methods: {
    search() {
      this.data = []
      const query = Object.assign(_.cloneDeep(defaultQuery), {
        qualifieField: this.sortBy,
        qualifieCount: this.minNum[this.sortBy],
        authorGroupIds: [this.groupId],
      }, this.dayQuery)
      
      this.loading = true
      this.$api.core.AwemeService.queryStatistics(query).then(rsp => {
        this.data = rsp.data || [];
      }).finally(() => this.loading = false);
    },
    onRowSelected(keys, rows) {
      let row = rows[0];
      this.$refs.qualifiedAweme.$emit('init', { record: row });
    },
    onRowDeselect(){ //取消选中所有行
      this.$refs.qualifiedAweme.$emit('init',{});
    },
    init(options) {
      this.data = [];
      this.search();
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    //初始化
    this.$on("init", this.init);
  }
};
</script>
