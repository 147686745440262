<template>
  <section class="afd-NewVideo" :style="{'--afd-item-total': modelValue.length || 1}">
    <VideoItem
      v-for="(items, index) of modelValue"
      :items="items"
      :key="`video_${index}_${items.play_url}`"
      :index="index"
      @remove="handleRemove"
    />
    <div class="afd-NewVideo__not-data" v-if="!(modelValue || []).length">目前没有在观看的直播</div>
  </section>
</template>

<script>
import VideoItem from "./VideoItem";

export default {
  name: "NewVideo",
  components: { VideoItem },
  data() {
    return {};
  },
  props: {
    value: Array
  },
  methods: {
    // 处理删除视频
    handleRemove(i) {
      this.modelValue.splice(i, 1);
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped lang="less">
@itemWidth: 272px;

.afd-NewVideo {
  height: 621px;
  width: calc((@itemWidth + 40px) * var(--afd-item-total));
  display: flex;
  align-items: center;

  &__not-data {
    width: 260px;
    height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}
</style>
