<!--表单-->
<template>
  <a-tabs v-model="activeTab" type="card" @change="onTabChange">
    <a-tab-pane key="total" tab="总量">
      <v-table ref="total" :columns="totalColumns" :remote="totalRemote" :query="totalQuery" :pagination="totalPagination" row-key="authorId">
        <template #orderNum="{$rowNum}">
          <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
        </template>
        <template #code="{row}">
          <author-card :value="row" :dataType="$root.dataType" small></author-card>
        </template>
      </v-table>
    </a-tab-pane>
    <a-tab-pane key="gap" tab="增量">
      <day-span v-model="daySpan" @change="search"></day-span>
      <v-table ref="gap" :columns="gapColumns" :remote="gapRemote" :query="gapQuery" :pagination="gapPagination" row-key="authorId">
        <template #orderNum="{$rowNum}">
          <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
        </template>
        <template #code="{row}">
          <author-card :value="row.author" :dataType="$root.dataType" small></author-card>
        </template>
      </v-table>
    </a-tab-pane>
  </a-tabs>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>
import DaySpan from "@/components/widgets/day-span";
import AuthorCard from "@/components/widgets/author-card";

const defaultQuery = {
  orderBys: 'fansCountTotal|desc,manufactorIds|desc,dealerId|desc,authorId|desc',
  param: {
  },
  groupId: undefined
}
const defaultStatQuery = {
  orderBys: 'gapFansCount|desc,authorId|desc',
  dayOffset: undefined,
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  groupBy: 'authorId',
  groupIds: undefined,
  start: 0,
  limit: 100
}

export default {
  components: { DaySpan, AuthorCard },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalQuery: _.cloneDeep(defaultQuery),
      gapQuery: _.cloneDeep(defaultStatQuery),
      loading: false,
      activeTab: 'total',
      totalPagination: { pageSize: 10 },
      gapPagination: { pageSize: 10 },
      daySpan: {
        dayOffset: 7,
        dayRange: []
      }
    };
  },
  computed: {
    totalColumns() {
      return [
        { title: "排序", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: 'code', scopedSlots: { customRender: 'code' }, ellipsis: true, width: 200 },
        { title: '总粉丝数', dataIndex: 'fansCountTotal', sorter: true, defaultSortOrder: 'descend', customRender: this.$dict.humanReadable, width: 80 },
        { title: '总点赞量', dataIndex: 'likeCountTotal', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '总作品数', dataIndex: 'awemeCountTotal', sorter: true, customRender: this.$dict.humanReadable, width: 80 }
      ]
    },
    gapColumns() {
      return [
        { title: "排序", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: 'code', scopedSlots: { customRender: 'code' }, ellipsis: true, width: 200 },
        { title: '新增粉丝数', dataIndex: 'gapFansCount', sorter: true, defaultSortOrder: 'descend', customRender: this.$dict.humanReadable, width: 80 },
        { title: '新增获赞量', dataIndex: 'gapDiggCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '新增作品数', dataIndex: 'gapAwemeCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 }
      ]
    },
    dayQuery() {
      return {
        dateRanges: this.daySpan.dayRange.length ? {
          dateRange: {
            startDate: this.daySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.daySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.daySpan.dayRange.length ? undefined : (this.daySpan.dayOffset || 7)
      }
    }
  },
  methods: {
    onTabChange(activeTab) {
      this.$nextTick(() => this.search())
    },
    totalRemote(qp) {
      return this.$api.core.AuthorService.query(qp);
    },
    gapRemote(qp) {
      return this.$api.core.AuthorService.queryGap(qp);
    },
    search(reset = false) {
      this.totalQuery.groupId = this.groupId
      Object.assign(this.gapQuery, { groupIds: [this.groupId] }, this.dayQuery)
      this.$refs[this.activeTab].load({ reset: reset });
    },
    init(options = {}) {
      this.onTabChange(this.activeTab)
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
