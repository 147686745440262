import { render, staticRenderFns } from "./AwemeComment.vue?vue&type=template&id=2410c2eb&scoped=true&"
import script from "./AwemeComment.vue?vue&type=script&lang=js&"
export * from "./AwemeComment.vue?vue&type=script&lang=js&"
import style0 from "./AwemeComment.vue?vue&type=style&index=0&id=2410c2eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2410c2eb",
  null
  
)

export default component.exports