<template>
  <a-row :gutter="[0, 20]">
    <a-col>
      <a-card>
        <a-page-header slot="title" :title="group.name" :sub-title="authorCount + '位'" style="padding: unset"/>
        <group-authors :group-id="groupId" @loaded="onLoad"></group-authors>
      </a-card>
    </a-col>
    <a-card>
      <div v-sticky="{zIndex:1000,top:0,fixTarget:'#gaManageTab'}">
        <a-tabs v-model="activeTab" @change="onTabChange" id="gaManageTab">
          <template #tabBarExtraContent>
            数据更新时间：{{ mtime || '-' }}
          </template>
          <a-tab-pane key="authorSort" tab="播主排名">

          </a-tab-pane>
          <a-tab-pane key="awemeAnalysis" tab="短视频分析">

          </a-tab-pane>
          <a-tab-pane key="liveAnalysis" tab="直播分析">

          </a-tab-pane>
          <a-tab-pane key="isALive" tab="正在直播"></a-tab-pane>
        </a-tabs>
      </div>
      <author-sort ref="authorSort" :group-id="groupId" v-if="activeTab=='authorSort'"></author-sort>
      <aweme-analysis ref="awemeAnalysis" :group-id="groupId" v-if="activeTab=='awemeAnalysis'"></aweme-analysis>
      <live-analysis ref="liveAnalysis" :group-id="groupId" v-if="activeTab=='liveAnalysis'"></live-analysis>
      <isALive ref="isALive" :group-id="groupId" v-if="activeTab=='isALive'"></isALive>
    </a-card>
  </a-row>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}

.sticky[fixed] {
  padding-top: 90px;
}

.sticky-fixed {
  background: #fff;
}

</style>
<script>

import GroupAuthors from "./GroupAuthors";
import AuthorSort from "./AuthorSort";
import AwemeAnalysis from "./awemeAnalysis";
import LiveAnalysis from "./liveAnalysis";
import isALive from "./isALive";

export default {
  components: { GroupAuthors, AuthorSort, AwemeAnalysis, isALive, LiveAnalysis },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'authorSort',
      authorCount: 0,
      mtime: '',
      group: {
        name: ''
      }
    };
  },
  computed: {},
  methods: {
    onLoad(l) {
      this.authorCount = l.length, l.length && (this.mtime = l.map(l => l.mtime).reduce((a, b) => a > b ? a : b));
    },
    onTabChange(activeTab) {
      this.$nextTick(() => this.$refs[activeTab].$emit('init'));
    },
    load() {
      this.$api.FavoriteGroupService.get(this.groupId).then(res => {
        this.group = res;
      });
    },
    init(options = {}) {
    }
  },
  created() {
  },
  mounted() {
    this.load();
    this.onTabChange(this.activeTab);
  }
};
</script>
