<template>
  <div>
    <div class="video_content">
      <!--      <div class="video_item1" v-if="liveList.length == 0"><p>目前没有在观看的直播</p></div>-->
      <!--      <Video
              v-for="(item, index) of liveList"
              :key="item.author_id"
              :video-url="item.play_url"
              :video-poster="item.cover"
              :room-id="item.room_id"
              :title="item.title"
              :author-id="item.author_id"
              muted
              autoplay
              @remove="removeVideo(index)"
              v-if="false"
            />-->
      <!--<a-button @click="handlePlay">添加直播</a-button>-->
      <NewVideo v-model="liveList" v-if="true"/>
    </div>
    <div class="button_box">
      <a-button icon="sync" @click="refresh" :disabled="refreshDisabled">10秒后刷新列表</a-button>
      <p class="number">正在直播 {{ liveTableList.length }} 个</p>
    </div>
    <div class="table_content">
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="liveTableList"
        :loading="tableLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
        bordered
      >
        <div slot='num' slot-scope='text, record, index'>
          {{ parseInt(index) + 1 }}
        </div>
        <div slot='info' slot-scope='text, record'>
          <div class="info_content">
            <div class="img_box">
              <img :src="record.author_info.avatar || ''" alt="头像">
            </div>
            <div class="item_box">
              <p class="name"><span class="name1">昵称：</span>{{ record.author_info.nickname }}</p>
              <p class="item"><span class="item1">经销商号：</span>{{ record.author_info.dealer_name }}</p>
              <p class="item"><span class="item1">抖音号：</span>{{ record.author_info.id }}</p>
              <p class="item"><span class="item1">粉丝数：</span>{{ record.author_info.fans_count }}</p>
            </div>
          </div>
        </div>
        <div slot='titles' slot-scope='text, record'>
          <div class="info_title_content">
            <div class="img_fm_box">
              <img :src="record.cover || ''" alt="封面">
            </div>
            <p class="title">{{ record.title || '--' }}</p>
          </div>
        </div>
        <div slot='start_time' slot-scope='text'>
          <p>{{ text }}</p>
        </div>
        <div slot='data' slot-scope='text, record'>
          <p class="data_item"><span>新增粉丝:</span>{{ record.follow_count }}</p>
          <p class="data_item"><span>送礼用户:</span>{{ record.gift_uv_count }}</p>
          <p class="data_item"><span>直播音浪:</span>{{ record.room_ticket_count }}</p>
          <p class="data_item"><span>直播观看:</span>{{ record.watch_cnt }}</p>
          <p class="data_item"><span>直播点赞:</span>{{ record.like_count }}</p>
          <p class="data_item"><span>累计观看:</span>--</p>
        </div>
        <div slot="operation" slot-scope='text, record'>
          <a-button
            type="primary"
            @click="openVideo(record)"
            :loading="record.__LOADING__"
            :disabled="(liveList || []).some(v => v.author_id === record.author_info.id)"
          >
            {{ (liveList || []).some(v => v.author_id === record.author_info.id) ? '观看中' : '观看' }}
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import api from "@/api/isALive.js";
import Video from "./Component/Video";
import NewVideo from "./Component/NewVideo";
import Paho from "@/utils/mqttws31.min.js";

export default {
  name: "index",
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "",
          scopedSlots: { customRender: "num" },
          width: 70
        },
        {
          title: "抖音号",
          dataIndex: "",
          scopedSlots: { customRender: "info" }
        },
        {
          title: "直播主题",
          dataIndex: "",
          scopedSlots: { customRender: "titles" }
        },
        {
          title: "直播时间",
          dataIndex: "start_time",
          scopedSlots: { customRender: "start_time" }
        },
        {
          title: "直播过程数据",
          dataIndex: "",
          scopedSlots: { customRender: "data" }
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 90
        }
      ],
      liveTableList: [],
      tableLoading: false,
      refreshDisabled: false,
      play_disabled: false,
      isBarrage: false,
      key: [],
      play_name: '观看',
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 17 }
      },
      liveList: [
        /*{
          play_url: 'http://s1.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
          cover: '',
          room_id: '',
          author_id: ''
        }*/
      ],
      mqttConfigData: {},
      client: null
    };
  },
  components: { Video, NewVideo },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      let payload = {
        group_id: this.groupId
      };
      this.tableLoading = true;
      api.getTableList(payload).then((res) => {
        this.tableLoading = false;
        if (res.code == 0) {
          this.liveTableList = (res.data || []).map(v => _.merge(v, { __LOADING__: false }));
        }
      });
    },
    openVideo(row) {
      console.log(row);
      this.$set(row, "__LOADING__", true);
      const { room_id, author_info, play_url, cover, title } = row || {};
      api.livingStatus({
        room_id,
        author_id: author_info.id
      }).then((res) => {
        if (res.code == 0) {
          this.$set(row, "__LOADING__", false);
          const that = this;
          if (res.data == 2) {
            that.liveList.push({
              play_url: play_url,
              cover: cover || '',
              room_id,
              title,
              author_id: author_info.id
            });
          } else if (res.data == 4) {
            that.$info({
              title: '直播已结束',
              onOk() {
                that.getTableList();
              }
            });
          }
        } else {
          this.$message.error("直播数据获取失败！");
          this.$set(row, "__LOADING__", false);
        }
      }).catch(() => {
        this.$message.error("直播数据获取失败！");
        this.$set(row, "__LOADING__", false);
      });
    }, // 观看
    refresh() {
      this.getTableList();
      this.refreshDisabled = true;
      let clear = setTimeout(() => {
        this.refreshDisabled = false;
        clearTimeout(clear);
      }, 10000);

    },
    removeVideo(index) {
      this.liveList.splice(index, 1);
    },
    handlePlay() {
      this.liveList.push({
        author_id: "2145841200242296",
        cover: "http://p3-webcast.douyinpic.com/img/tos-cn-i-0813/bce6985f686c45639466c687ff7328d0~tplv-resize:0:0.image",
        play_url: "http://s1.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4",
        room_id: "7031522819385117475",
        title: "郑州恒信众和上汽…正在"
      });
    }
  },
  destroyed() {
    // console.log("销毁");
    if (this.client) {
      this.client.unsubscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
  }
};
</script>

<style lang="less" scoped>
.video_content {
  overflow-x: auto;
  width: auto;
  //height: 580px;
  /* width: 100%;
   overflow-x: scroll;
   padding: 10px;
   display: flex;
   align-items: center;

   .video_item {
     padding-right: 40px;
     position: relative;

     .play-circle {
       position: absolute;
       left: 50%;
       top: 50%;
       transform: translate(-50%, -50%);
     }

     .sound {
       position: absolute;
       top: -36px;
       left: 50%;
       transform: translateX(-50%);
     }

     .close-circle {
       position: absolute;
       top: -10px;
       right: 32px;
     }
   }

   .video_item1 {
     width: 260px;
     height: 390px;
     display: flex;
     justify-content: center;
     align-items: center;
     border: 1px solid #ccc;
   }*/
}

.info_content {
  display: flex;
  align-items: center;

  .img_box {
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.item_box {
  .name1 {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }

  .item1 {
    font-size: 14px;
    font-weight: bold;
    color: #0b0b0b;
  }
}

.info_title_content {
  display: flex;

  .img_fm_box {
    width: 86px;
    height: 148px;
    border: 1px solid #cccccc;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.button_box {
  display: flex;
  align-items: center;
  padding-top: 20px;

  .number {
    font-size: 32px;
    font-weight: bold;
    padding-left: 20px;
    line-height: 2px;
    margin-top: 33px;
  }
}

.data_item {
  span {
    font-size: 14px;
    font-weight: bold;
    color: #0b0b0b;
  }
}
</style>
