<template>
  <div>
    <!-- chart [start]-->
    <div style="text-align:center">
      <a-row type="flex" :gutter="5" style="width:300px;margin: 0 auto;">
        <a-col :span="12" style="display:flex;align-items:center;justify-content:center;">
          <div>
            <div>入围占比</div>
            <div style="font-weight:bold;text-align:center">{{statistics.scale + '%'}}</div>
          </div>
        </a-col>
        <a-col :span="12">
          <div ref="pieChart" :style="{width: '100%', height: '100px'}"></div>
        </a-col>
      </a-row>
    </div>
    <!-- chart[end]-->
    <a-row type="flex">
      <a-col flex="auto">
        <div style="font-weight:bold;margin-bottom:10px">入围视频TOP10</div>
      </a-col>
      <a-col>
        <a-button type="link" :disabled="!authorId" @click="show">查看更多>></a-button>
      </a-col>
    </a-row>
    <v-table ref="videoTable" size="small" :columns="topColumns" :data="data" :pagable="false"
      row-key="awemeId" class="table-border-none">
      <template #orderNum="{$index}">
        <span class="order-num" :le3="$index<3">{{$index+1}}</span>
      </template>
    </v-table>
    <a-drawer :width="1000" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="visible = false">
      <template #title>
        <div style="display: flex; margin-top: -4px;">
          <span style="flex: auto">入围视频</span>
          <a-button type="primary" icon="export" @click="exportExcel" :loading="loading" style="margin-right: 20px" >导出</a-button>
        </div>
      </template>
      <v-table ref="table" :columns="columns" :remote="remote" :query="query" :pagination="pagination" row-key="awemeId">
        <template #orderNum="{$rowNum}">
          <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
        </template>
        <template #code="{row}">
          <aweme-card :value="row" :dataType="$root.dataType" link small></aweme-card>
        </template>
        <!-- todo -->
        <template #operation="{row}">
          <router-link :to="{ name: 'aweme:analysis', params: { awemeId: row.awemeId } }" target="_blank">视频分析</router-link>
        </template>
      </v-table>
    </a-drawer>
  </div>
</template>
<style lang="less" scoped>
.table-border-none .order-num{
  width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 22px;
    background: #eee;
    display: block;
    line-height: 22px;
}
.table-border-none .order-num[le3="true"]{
    background: #666;
    color: white;
}
</style>
<script>
import AwemeCard from "@/components/widgets/aweme-card"

const defaultQuery = {
  orderBys: 'playVolume|desc',
  dayOffsetField: 'publishTime',
  param: {
    authorId: undefined
  },
  start: 0,
  limit: 10
}
export default {
  components: { AwemeCard },
  props: {
    sortBy: {
      type: String,
      required: true,
      default: 'diggCount'
    },
    dayQuery: {
      type: Object,
      required: true,
      default: () => {}
    },
    minNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      query: _.cloneDeep(defaultQuery),
      pagination: { pageSize: 5 },
      sortLabels: {
        playVolume: '播放',
        diggCount: '点赞',
        commentCount: '评论',
        shareCount: '转发'
      },
      authorId: undefined,
      statistics: {
        qualifieCount: 0,
        totalCount: 0,
        scale: 0
      },
      data: []
    };
  },
  computed: {
    topColumns() {
      return [
        { title: "", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 45 },
        { title: "作品", dataIndex: "awemeTitle", ellipsis: true },
        { title: `${this.sortLabels[this.sortBy]}量`, dataIndex: this.sortBy, customRender: this.$dict.humanReadable, width: 60 }
      ]
    },
    columns() {
      return [
        { title: '排序', dataIndex: 'orderNum', scopedSlots: { customRender: 'orderNum' }, width: 45 },
        { title: '视频', dataIndex: 'code', scopedSlots: { customRender: 'code' }, width: 200, ellipsis: true },
        { title: '播放量', dataIndex: 'playVolume', customRender: this.$dict.humanReadable, width: 80 },
        { title: '点赞量', dataIndex: 'diggCount', customRender: this.$dict.humanReadable, width: 80 },
        { title: '评论量', dataIndex: 'commentCount', customRender: this.$dict.humanReadable, width: 80 },
        { title: '转发量', dataIndex: 'shareCount', customRender: this.$dict.humanReadable, width: 80 },
        { title: "操作", scopedSlots: { customRender: "operation" }, width: 70 }
      ]
    },
    yd() {
      return this.statistics.scale
    },
    nd() {
      return (1000 - this.yd * 10) / 10
    },
  },
  methods: {
    remote(qp) {
      return this.$api.core.AwemeService.query(qp);
    },
    load() {
      Object.keys(this.query).filter(k => k.endsWith('Gte') && this.$delete(this.query, k))
      Object.assign(this.query, {
        orderBys: this.sortBy + '|desc',
        [this.sortBy + 'Gte']: this.minNum,
        param: {
          authorId: this.authorId
        }
      }, this.dayQuery)
      this.loading = true
      this.$api.core.AwemeService.query(this.query).then(rsp => {
        this.data = rsp.data || []
      }).finally(() => this.loading = false);
    },
    refreshChart() {
      let options = this.pieChart.getOption();
      let s = options.series[0];
      s.data = []
      this.statistics.qualifieCount && s.data.push({
        name: '已入围', value: this.statistics.qualifieCount,
        tooltip: { formatter: `{a} <br/>{b}: {c} (${this.yd}%)` }
      })
      s.data.push({ name: '未入围', value: "" + (this.statistics.totalCount - this.statistics.qualifieCount),
        itemStyle: {
          normal: { color: '#f0f2f5' },
          emphasis: { color: '#f0f2f5' }
        },
        tooltip: { formatter: `{a} <br/>{b}: {c} (${this.nd}%)` }
      })
      this.pieChart.setOption(options);
    },
    show() {
      this.visible = true
      this.$nextTick(() => this.$refs.table.load({ reset: true }))
    },
    exportExcel() {
      this.loading = true;
      this.$api.core.AwemeService.exportShortListedAweme(this.query).finally(()=>{
        this.loading = false
      });
    },
    initChart() {
      let $el = this.$refs.pieChart;
      //参考https://echarts.apache.org/examples/zh/editor.html?c=pie-doughnut
      this.pieChart = this.$echarts.init($el);
      // 绘制图表
      this.pieChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ["#1890ff", "#f0f2f5"],
        series: [
          //纬度
          {
            name: "入围情况",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              //展示到中心
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold"
              }
            },
            data: [
              { value: 0, name: "已入围" },
              { value: 0, name: "未入围" }
            ]
          }
        ]
      });
    },
    init(options) {
      this.data = []
      if (options.record) {
        const awemeStatistic = (options.record || {})
        this.authorId = awemeStatistic.author.authorId
        this.statistics = {
          qualifieCount: awemeStatistic.qualifieCount,
          totalCount: awemeStatistic.totalCount,
          scale: Math.floor(awemeStatistic.qualifieCount * 100 / (awemeStatistic.totalCount || awemeStatistic.qualifieCount))
        }
        //根据参数加载图
        this.refreshChart();
        //更新数据
        this.load();
      } else {
        this.authorId = undefined
        this.statistics = {
          qualifieCount: 0,
          totalCount: 0,
          scale: 0
        }
        this.data =  [];
        this.refreshChart();
      }
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    //绘制图
    this.initChart();
    //初始化
    this.$on("init", this.init);
  }
};
</script>
