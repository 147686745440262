var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"text-align":"center"}},[_c('a-row',{staticStyle:{"width":"300px","margin":"0 auto"},attrs:{"type":"flex","gutter":5}},[_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":12}},[_c('div',[_c('div',[_vm._v("入围占比")]),_c('div',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v(_vm._s(_vm.statistics.scale + '%'))])])]),_c('a-col',{attrs:{"span":12}},[_c('div',{ref:"pieChart",style:({width: '100%', height: '100px'})})])],1)],1),_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"flex":"auto"}},[_c('div',{staticStyle:{"font-weight":"bold","margin-bottom":"10px"}},[_vm._v("入围视频TOP10")])]),_c('a-col',[_c('a-button',{attrs:{"type":"link","disabled":!_vm.authorId},on:{"click":_vm.show}},[_vm._v("查看更多>>")])],1)],1),_c('v-table',{ref:"videoTable",staticClass:"table-border-none",attrs:{"size":"small","columns":_vm.topColumns,"data":_vm.data,"pagable":false,"row-key":"awemeId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $index = ref.$index;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$index<3}},[_vm._v(_vm._s($index+1))])]}}])}),_c('a-drawer',{attrs:{"width":1000,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":function($event){_vm.visible = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticStyle:{"display":"flex","margin-top":"-4px"}},[_c('span',{staticStyle:{"flex":"auto"}},[_vm._v("入围视频")]),_c('a-button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary","icon":"export","loading":_vm.loading},on:{"click":_vm.exportExcel}},[_vm._v("导出")])],1)]},proxy:true}])},[_c('v-table',{ref:"table",attrs:{"columns":_vm.columns,"remote":_vm.remote,"query":_vm.query,"pagination":_vm.pagination,"row-key":"awemeId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $rowNum = ref.$rowNum;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$rowNum<3}},[_vm._v(_vm._s($rowNum+1))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('aweme-card',{attrs:{"value":row,"dataType":_vm.$root.dataType,"link":"","small":""}})]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'aweme:analysis', params: { awemeId: row.awemeId } },"target":"_blank"}},[_vm._v("视频分析")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }