<template>
  <div class="afd-VideoItem">
    <div class="afd-VideoItem__muted">
      <a-icon
          type="sound"
          theme="filled"
          :style="{color: isMuted ? '#cccccc' : '#52c41a'}"
          class="afd-VideoItem__muted--icon"
          @click="handleMuted"
      />
    </div>
    <div class="afd-VideoItem__wrapper">
      <a-icon
          type="close-circle"
          theme="filled"
          class="afd-VideoItem__wrapper--close"
          @click="handleRemove"
      />

      <!-- 视频容器 -->
      <div class="afd-VideoItem__video" ref="PlayerBarrage" @click="handleVideoPausePlay">
        <EasyPlayer
            :video-url="playUrl"
            @message="handleVideoMsg"
            fluent
            aspect="9:16"
            stretch
            style="width: 270px"
            :live="false"
            :speed="false"
            :loading="loading"
            :show-custom-button="false"
            :muted="isMuted"
            ref="EasyPlayer"
            @video-url="handleVideoUrl"
            @ended="handleEnded"
        />
      </div>

      <!-- 弹幕 -->
      <div class="afd-VideoItem__barrage" ref="barrage"></div>
      <!-- 额外遮罩 -->
      <div class="afd-VideoItem__overlay">
        <div class="afd-VideoItem__overlay--content" :class="{'is-show':showOverlay}">
          <p v-if="isTry">{{ LS_OBJ[livingStatus] }}</p>
          <a-icon
              type="play-circle"
              theme="filled"
              class="afd-VideoItem__play"
              v-if="!isTry && videoPaused"
          />
        </div>
      </div>
    </div>
    <header class="afd-VideoItem__header">《{{ items.title || "无标题" }}》</header>
  </div>
</template>

<script>
import EasyPlayer from '@easydarwin/easyplayer';
import DanmuJs from "danmu.js";
import Request from "@/api/isALive.js";
import Paho from "@/utils/mqttws31.min";
import _ from "lodash";

const tryNum = [4, 1000]; // 异常状态数组

// 添加监听
const eventTester = function (el, evt, fn) {
  el.addEventListener(evt, fn, false);
};

// 去除监听
const removeEventTester = function (el, evt, fn) {
  el.removeEventListener(evt, fn, false);
};

export default {
  name: "VideoItem",
  components: {EasyPlayer},
  props: {
    items: Object,
    index: Number,
    showBarrage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tryNum,
      isMuted: true,
      livingStatus: null,
      LS_OBJ: {
        1000: "推流异常",
        4: "直播已结束"
      },
      mqttConfigData: null,
      videoPaused: false,
      loading: false,
      REF_VIDEO: null
    };
  },
  methods: {
    // 添加事件监听
    handleVideo () {
      this.loading = true;
      this.$nextTick(() => {
        const video = this.REF_VIDEO = this.$refs.EasyPlayer.player.tech_.el_;
        eventTester(video, "stalled", this.handleStalled);
        eventTester(video, "waiting", this.handleWaiting);
        eventTester(video, "play", this.handlePlay);
        eventTester(video, "canplay", this.handleCanplay);
        eventTester(video, "error", this.handleError);
        eventTester(video, "loadstart", this.handleLoadstart);
        // eventTester(video, "ended", this.handleEnded);
      });
    },
    // 处理移除事件监听
    handleRemoveEvent () {
      const video = this.REF_VIDEO;
      removeEventTester(video, "stalled", this.handleStalled);
      removeEventTester(video, "waiting", this.handleWaiting);
      removeEventTester(video, "play", this.handlePlay);
      removeEventTester(video, "canplay", this.handleCanplay);
      removeEventTester(video, "error", this.handleError);
      // removeEventTester(video, "ended", this.handleEnded);
    },
    // 处理开始请求数据
    handleLoadstart (evt) {
      this.loading = true;
      console.log(evt.type, "处理开始请求数据");
    },
    // 处理网络失速
    handleStalled (evt) {
      console.log(evt.type, "处理网络失速");
    },
    // 处理等待
    handleWaiting (evt) {
      this.loading = true;
      console.log(evt.type, "处理等待");
    },
    // 处理播放
    handlePlay (evt) {
      this.loading = false;
      console.log(evt.type, "处理播放");
    },
    // 处理可以播放
    handleCanplay (evt) {
      this.loading = false;
      console.log(evt.type, "处理可以播放");
    },
    // 处理发生错误
    handleError (evt) {
      // const video = this.$refs.EasyPlayer.player.tech_.el_;
      this.getLivingStatus('Error');
      console.log(evt.type, "处理请求数据发生错误");
      // this.livingStatus = 1000;
      this.loading = false;
    },
    // 处理音量控制
    handleMuted () {
      this.isMuted = !this.isMuted;
      const video = this.$refs.EasyPlayer.player.tech_.el_;
      video.muted = this.isMuted;
    },
    // 视频消息
    handleVideoMsg (msg) {
      console.log('message', msg);
      // this.getLivingStatus();
      this.livingStatus = 1000;
      this.loading = false;
      if (this.client) {
        this.client.unsubscribe(
            this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
        );
        this.client.disconnect(); // 断开连接
        this.client = null;
      }
    },
    // 处理播放暂停
    handleVideoPausePlay () {
      // 当出现异常状态时候不作处理
      if (this.isTry) return;
      const video = this.$refs.EasyPlayer.player.tech_.el_;
      if (!video.paused) {
        video.pause();
      } else {
        video.play();
      }
      this.videoPaused = video.paused;
    },
    // 触发通知消息
    handleVideoUrl () {
      console.log("video-url", arguments);
    },
    // 处理播放结束
    handleEnded () {
      console.log("播放结束了");
      this.livingStatus = 4;
      this.loading = false;
      if (this.client) {
        this.client.unsubscribe(
            this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
        );
        this.client.disconnect(); // 断开连接
        this.client = null;
      }
    },
    // 获取直播状态
    getLivingStatus (type) {
      this.livingStatus = null;
      const {room_id, author_id} = this.items;
      Request.livingStatus({room_id, author_id})
          .then(res => {
            const {code, data} = res;
            if (code === 0) {
              if (data === 2 && type == 'Error') {
                this.livingStatus = 1000;
                if (this.client) {
                  this.client.unsubscribe(
                      this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
                  );
                  this.client.disconnect(); // 断开连接
                  this.client = null;
                }
              } else if (data === 4) {
                this.loading = false;
                if (this.client) {
                  this.client.unsubscribe(
                      this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
                  );
                  this.client.disconnect(); // 断开连接
                  this.client = null;
                }
              } else {
                this.livingStatus = data;
              }
            }
          });
    },
    // 初始化弹幕
    handleInitBarrage () {
      this.danmujs = new DanmuJs({
        container: this.$refs.barrage, // 弹幕容器，该容器发生尺寸变化时会自动调整弹幕行为
        live: true,
        player: this.$refs.PlayerBarrage, // 配合音视频元素（video或audio）同步使用时需提供该项
        comments: [ // 弹幕预存数组,配合音视频元素（video或audio）同步使用时需提供该项
          /*{
            duration: 20000, // 弹幕持续显示时间,毫秒(最低为5000毫秒)
            //moveV: 100, //弹幕匀速移动速度(单位: px/秒)，设置该项时duration无效
            id: '1', //弹幕id，需唯一
            start: 2000, //弹幕出现时间（毫秒）
            prior: true, //该条弹幕优先显示，默认false
            color: true, //该条弹幕为彩色弹幕，默认false
            txt: '长弹幕长弹幕长弹幕长弹幕长弹幕', //弹幕文字内容
            style: {  //弹幕自定义样式
              color: '#ff9500',
              fontSize: '20px',
              padding: '2px 11px'
            },
            mode: 'top' //显示模式，top顶部居中，bottom底部居中，scroll滚动，默认为scroll
            /!* like: { // 点赞相关参数
               el: likeDOM, // el 仅支持传入 dom
               style: { // el 绑定样式
                 paddingLeft: '10px',
                 color: '#ff0000'
               }
             }*!/
            // el: DOM //直接传入一个自定义的DOM元素作为弹幕，使用该项的话会忽略所提供的txt和style
          }*/
        ],
        area: {  //弹幕显示区域
          start: 0, //区域顶部到播放器顶部所占播放器高度的比例
          end: 1 //区域底部到播放器顶部所占播放器高度的比例
        }
        // interval: 1500, // 循环间隔
        // containerStyle: { zIndex: 100}, //弹幕容器样式
        // channelSize: 40, // 轨道大小
        // mouseControl: true, // 打开鼠标控制, 打开后可监听到 bullet_hover 事件。danmu.on('bullet_hover', function (data) {})
        // mouseControlPause: false, // 鼠标触摸暂停。mouseControl: true 生效
        // //bOffset: 1000, //可调节弹幕横向间隔（毫秒）
        // defaultOff: true //开启此项后弹幕不会初始化，默认初始化弹幕
      });
      this.getBarrageConfig();
    },
    // 获取弹幕配置
    getBarrageConfig () {
      const {author_id} = this.items;
      Request.getBarrage({platform_type: 1, author_id, device_id: +new Date()})
          .then(res => {
            const {data} = res;
            if (data.code === 0) {
              this.mqttConfigData = data.data;
              // console.log(this.mqttConfigData);
              this.getBarrageLink();
            }
          });
    },
    // 建立弹幕链接
    getBarrageLink () {
      const {endPoint, clientId, userName, password} = this.mqttConfigData;
      this.client = new Paho.MQTT.Client(endPoint, 443, clientId);
      // 连接服务器并注册连接成功处理事件
      this.client.connect({
        userName, //连接帐号
        password, //密码
        cleanSession: true,
        onSuccess: this.handleBarrageSend,
        useSSL: true,
        // 处理链接失败
        onFailure: () => {
          // console.log('弹幕链接失败');
          setTimeout(this.getBarrageLink, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4
      });
      // 注册连接断开处理事件
      this.client.onConnectionLost = this.handleBarrageLost;
      // 注册消息接收处理事件
      this.client.onMessageArrived = this.handleBarrageArrived;
    },
    // 处理弹幕发送
    handleBarrageSend () {
      //建立连接后，进行订阅并发送消息。
      console.log("弹幕连接成功", this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic);
      const {clientSubscribe} = this.mqttConfigData;
      //建立连接后，进行订阅并发送消息。
      this.client.subscribe(
          clientSubscribe.live.bulletAndStatisticsTopic,
          {qos: 0}
      );
    },
    // 处理弹幕断开
    handleBarrageLost (responseObject) {
      console.log('弹幕断开');
      if (responseObject.errorCode !== 0) {
        // console.log("onConnectionLost:" + responseObject.errorMessage);
        console.error(responseObject);
      }
      // throw new Error(_err);
    },
    // 当弹幕消息到达时 处理弹幕送达
    handleBarrageArrived (res) {
      const bulletAndStatisticsTopic = this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic;
      const index = bulletAndStatisticsTopic.lastIndexOf("/");
      const url = bulletAndStatisticsTopic.substring(index + 1, bulletAndStatisticsTopic.length);
      const newJson = JSON.parse(res.payloadString);
      const {author_id} = this.items;
      console.log(newJson, newJson.type === '1001' && author_id == url, 'newJson');
      if (newJson.type === '1001' && author_id == url) {
        const item = newJson.data;
        const key = _.uniqueId(`fctc_${ this._uid }_`);
        this.danmujs.sendComment({  //发送弹幕
          duration: 4500, // 动画时间
          id: key,
          // start: 3000, //不提供该项则立即发送
          txt: `${ item.userName }: ${ item.content }`,
          style: {
            color: 'yellow',
            fontSize: '16px'
          }
        });
      }
    },
    // 处理移除
    handleRemove () {
      this.$emit("remove", this.index);
    }
  },
  computed: {
    // 显示遮罩
    showOverlay () {
      return this.isTry || this.videoPaused;
    },
    // 当视频出现异常
    isTry () {
      return tryNum.includes(this.livingStatus);
    },

    // URL
    playUrl () {
      return this.items.play_url.replace(/^http/, "https");
    }
  },
  mounted () {
    this.showBarrage && this.handleInitBarrage();
    setTimeout(this.handleVideo, 200);
  },
  beforeDestroy () {
    this.handleRemoveEvent();
    if (this.client) {
      this.client.unsubscribe(
          this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
  }
};
</script>

<style scoped lang="less">
@width: 272px;
@borderRadius: 5px;

.afd-VideoItem {
  display: inline-block;
  margin: 20px;
  width: @width;
  height: 100%;
  padding-bottom: 10px;

  &:hover {
    .afd-VideoItem__wrapper--close {
      opacity: 1;
      height: 28px;
    }
  }

  // title
  &__header {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }

  // 音量控制
  &__muted {
    display: flex;
    justify-content: center;
    align-items: center;

    &--icon {
      font-size: 32px;
      cursor: pointer;
    }
  }

  // 视频容器
  &__wrapper {
    position: relative;
    min-height: 390px;
    //border-radius: @borderRadius;
    border: 1px solid #ccc;
    margin-top: 20px;

    &--close {
      font-size: 28px;
      position: absolute;
      top: 0;
      right: 0;
      color: #ccc;
      transform: translate(50%, -50%);
      z-index: 999;
      cursor: pointer;
      /*transition: opacity .3s .1s, height .3s, width .3s;
      opacity: 0;
      height: 0;
      display: inline-block;
      overflow: hidden;*/
    }
  }

  // 视频
  &__video {
    cursor: pointer;

    /deep/ .vjs-tech {
      //border-radius: @borderRadius;
    }

    /deep/ .vjs-control-bar {
      display: none;
    }
  }

  // 弹幕
  &__barrage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: @borderRadius;
    pointer-events: none;
    z-index: 99999;
    //background-color: rgba(0, 0, 0, .2);
  }

  // 额外遮罩
  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //border-radius: @borderRadius;
    pointer-events: none;
    z-index: 9999;
    cursor: pointer;

    &--content {
      width: 100%;
      height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      overflow: hidden;

      &.is-show {
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
      }

      p {
        font-size: 32px;
        color: #fff;
        text-align: center;
      }
    }
  }

  // 播放暂停
  &__play {
    font-size: 70px;
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
  }
}
</style>
