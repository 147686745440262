var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-tabs',{attrs:{"type":"card","id":"gaManageTab"},on:{"change":_vm.onTabChange},scopedSlots:_vm._u([{key:"tabBarExtraContent",fn:function(){return [_c('a-button',{attrs:{"type":"primary","icon":"export","loading":_vm.loading},on:{"click":_vm.exportExcel}},[_vm._v("导出")])]},proxy:true}]),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('a-tab-pane',{key:"total",attrs:{"tab":"总量"}},[_c('day-span',{attrs:{"label":"发布时间"},on:{"change":_vm.search},model:{value:(_vm.totalDaySpan),callback:function ($$v) {_vm.totalDaySpan=$$v},expression:"totalDaySpan"}}),_c('a-row',{staticStyle:{"padding":"16px 0"},attrs:{"type":"flex"}},[_c('a-col',{attrs:{"flex":"auto"}},[_c('v-statistics',{attrs:{"columns":_vm.statisticColumns,"data":_vm.statisticData}})],1)],1),_c('v-table',{ref:"total",attrs:{"columns":_vm.totalColumns,"remote":_vm.totalRemote,"query":_vm.totalQuery,"pagination":_vm.totalPagination,"row-key":"authorId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $rowNum = ref.$rowNum;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$rowNum<3}},[_vm._v(_vm._s($rowNum+1))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('author-card',{attrs:{"value":row.author,"dataType":_vm.$root.dataType,"small":""}})]}}])})],1),_c('a-tab-pane',{key:"gap",attrs:{"tab":"增量"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('day-span',{attrs:{"disabled-date":_vm.disabledDate},on:{"change":_vm.search},model:{value:(_vm.gapDaySpan),callback:function ($$v) {_vm.gapDaySpan=$$v},expression:"gapDaySpan"}}),_c('a-tooltip',{staticStyle:{"margin-left":"10px"},attrs:{"placement":"right","title":"统计数据: 近30天发布的作品"}},[_c('a-icon',{attrs:{"type":"question-circle"}})],1)],1),_c('a-row',{staticStyle:{"padding":"16px 0"},attrs:{"type":"flex"}},[_c('a-col',{attrs:{"flex":"auto"}},[_c('v-statistics',{attrs:{"columns":_vm.gapStatisticColumns,"data":_vm.gapStatisticData}})],1)],1),_c('v-table',{ref:"gap",attrs:{"columns":_vm.gapColumns,"remote":_vm.gapRemote,"query":_vm.gapQuery,"pagination":_vm.gapPagination,"row-key":"authorId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $rowNum = ref.$rowNum;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$rowNum<3}},[_vm._v(_vm._s($rowNum+1))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('author-card',{attrs:{"value":row.author,"dataType":_vm.$root.dataType,"small":""}})]}}])})],1)],1),_c('qualified-author',{ref:"qualifiedAuthor",attrs:{"group-id":_vm.groupId}}),_c('aweme-comment',{ref:"awemeComment",attrs:{"group-id":_vm.groupId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }