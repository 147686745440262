<template>
  <a-row type="flex" class="day-span" align="middle" style="padding: 10px 0">
    <slot name="label">
      <label v-if="label">{{ label }}：</label>
    </slot>
    <v-checktags v-model="value.dayOffset" :options="days" @change="onDayOffsetChange"></v-checktags>
    <a-range-picker allowClear v-model="value.dayRange" :disabled-date="disabledDate" format="YYYY-MM-DD"
                    @change="onDayRangeChange"></a-range-picker>
    <div class="input-number" v-if="type =='liveList'">
      <span>有效直播时长（min）:</span>
      <a-input-number
          v-model='value.min'
          placeholder='请输入数字，直播时长>=改数字的算有效'
          style='width:320px;margin-left: 10px'
          @blur="searchNum"
          :min="0"
          :precision="0"
      />
    </div>
  </a-row>
</template>

<script>
import VChecktags from "@/components/v-checktags";
import moment from 'moment';

const defaultValue = {
  dayOffset: 7,
  dayRange: []
};

export default {
  name: "time-span",
  components: {VChecktags},
  props: {
    value: {
      type: Object,
      default: () => _.cloneDeep(defaultValue)
    },
    label: {
      type: String
    },
    type: {
      type: String
    },
    disabledDate: {
      type: Function,
      default (current) {
        return current && current > moment().endOf('day');
      }
    }
  },
  watch: {
    value () {
      this.flushValue();
    }
  },
  data: () => ({
    days: [
      {text: '近7天', value: 7},
      {text: '近15天', value: 15},
      {text: '近30天', value: 30}
    ]
  }),
  methods: {
    searchNum () {
      console.log('请输入数字，直播时长>=改数字的算有效');
      if (this.value.min) {
        this.value.dayOffset && (this.value.dayRange = []);
        this.$emit('searchNum', this.value);
      } else {
        this.value.min = 0;
        this.value.dayOffset && (this.value.dayRange = []);
        this.$emit('searchNum', this.value);
      }

    },
    onDayOffsetChange () {
      this.value.dayOffset && (this.value.dayRange = []);
      this.$emit('change', this.value);
    },
    onDayRangeChange (nv) {
      this.value.dayOffset = (nv.length ? 0 : 7);
      this.$emit('change', this.value);
    },
    flushValue () {
      Object.entries(_.cloneDeep(defaultValue)).filter(([k]) => this.value[k] == undefined).forEach(([k, v]) => this.$set(this.value, k, v));
    }
  },
  mounted () {
    this.flushValue();
  }
};
</script>

<style scoped>
.day-span {
  padding: 10px 0;
}

.day-span .ant-tag-checkable {
  margin: 0;
  padding: 10px;
}

.input-number {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.input-number /deep/ .ant-input-number-handler-wrap {
  -webkit-appearance: none !important;
  margin: 0;
  display: none;
}
</style>
