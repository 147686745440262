<!--表单-->
<template>
  <div>
    <a-tabs v-model="activeTab" type="card" @change="onTabChange" id="gaManageTab" >
      <template #tabBarExtraContent>
        <a-button type="primary" icon="export" @click="exportExcel" :loading="loading">导出</a-button>
      </template>
      <a-tab-pane key="total" tab="总量">
        <day-span v-model="totalDaySpan" label="发布时间" @change="search"></day-span>
        <a-row type="flex" style="padding: 16px 0">
          <a-col flex="auto">
            <v-statistics :columns="statisticColumns" :data="statisticData"></v-statistics>
          </a-col>
        </a-row>
        <v-table ref="total" :columns="totalColumns" :remote="totalRemote" :query="totalQuery" :pagination="totalPagination" row-key="authorId">
          <template #orderNum="{$rowNum}">
            <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
          </template>
          <template #code="{row}">
            <author-card :value="row.author" :dataType="$root.dataType" small></author-card>
          </template>
        </v-table>
      </a-tab-pane>
      <a-tab-pane key="gap" tab="增量">
        <div style="display: flex; align-items: center">
          <day-span v-model="gapDaySpan" :disabled-date="disabledDate" @change="search"></day-span>
          <a-tooltip placement="right" title="统计数据: 近30天发布的作品" style="margin-left: 10px"><a-icon type="question-circle" /></a-tooltip>
        </div>
        <a-row type="flex" style="padding: 16px 0">
          <a-col flex="auto">
            <v-statistics :columns="gapStatisticColumns" :data="gapStatisticData"></v-statistics>
          </a-col>
        </a-row>
        <v-table ref="gap" :columns="gapColumns" :remote="gapRemote" :query="gapQuery" :pagination="gapPagination" row-key="authorId">
          <template #orderNum="{$rowNum}">
            <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
          </template>
          <template #code="{row}">
            <author-card :value="row.author" :dataType="$root.dataType" small></author-card>
          </template>
        </v-table>
      </a-tab-pane>
    </a-tabs>
    <qualified-author ref="qualifiedAuthor" :group-id="groupId"></qualified-author>
    <aweme-comment ref="awemeComment" :group-id="groupId"></aweme-comment>
  </div>
</template>
<style scoped>
</style>
<script>
import DaySpan from "@/components/widgets/day-span"
import AuthorCard from "@/components/widgets/author-card"
import QualifiedAuthor from "./QualifiedAuthor"
import AwemeComment from "./AwemeComment"
import moment from 'moment'

const defaultQuery = {
  orderBys: 'diggCount|desc,authorId|desc',
  dayOffsetField: 'publishTime',
  param: {
    authorIds: undefined
  },
  authorGroupIds: undefined,
  groupBy: undefined,
  start: 0,
  limit: 100
}
const defaultStatQuery = {
  orderBys: 'gapDiggCount|desc,authorId|desc',
  dayOffset: undefined,
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  param: {
    authorIds: undefined
  },
  authorGroupId: undefined,
  groupBy: undefined,
  start: 0,
  limit: 100
}

export default {
  components: { AwemeComment, DaySpan, AuthorCard, QualifiedAuthor },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalQuery: _.cloneDeep(defaultQuery),
      gapQuery: _.cloneDeep(defaultStatQuery),
      loading: false,
      activeTab: 'total',
      totalPagination: { pageSize: 5 },
      gapPagination: { pageSize: 5 },
      totalDaySpan: {
        dayOffset: 7,
        dayRange: []
      },
      gapDaySpan: {
        dayOffset: 7,
        dayRange: []
      },
      statisticColumns: [
        { title: '播放总量', dataIndex: 'playVolume', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '点赞总量', dataIndex: 'diggCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '评论总量', dataIndex: 'commentCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '转发总量', dataIndex: 'shareCount', formatter: ({ value }) => this.$dict.humanReadable(value) }
      ],
      statisticData: {},
      gapStatisticColumns: [
        { title: '播放增量', dataIndex: 'gapPlayVolume', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '点赞增量', dataIndex: 'gapDiggCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '评论增量', dataIndex: 'gapCommentCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '转发增量', dataIndex: 'gapShareCount', formatter: ({ value }) => this.$dict.humanReadable(value) }
      ],
      gapStatisticData: {}
    }
  },
  computed: {
    totalColumns() {
      return [
        { title: '排序', dataIndex: 'orderNum', scopedSlots: { customRender: 'orderNum' }, width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: 'code', scopedSlots: { customRender: 'code' }, ellipsis: true, width: 200 },
        { title: '播放总量', dataIndex: 'playVolume', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '点赞总量', dataIndex: 'diggCount', sorter: true, defaultSortOrder: 'descend', customRender: this.$dict.humanReadable, width: 80 },
        { title: '评论总量', dataIndex: 'commentCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '转发总量', dataIndex: 'shareCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 }
      ]
    },
    gapColumns() {
      return [
        { title: '排序', dataIndex: 'orderNum', scopedSlots: { customRender: 'orderNum' }, width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: 'code', scopedSlots: { customRender: 'code' }, ellipsis: true, width: 200 },
        { title: '播放增量', dataIndex: 'gapPlayVolume', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '点赞增量', dataIndex: 'gapDiggCount', sorter: true, defaultSortOrder: 'descend', customRender: this.$dict.humanReadable, width: 80 },
        { title: '评论增量', dataIndex: 'gapCommentCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '转发增量', dataIndex: 'gapShareCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 }
      ]
    },
    totalDayQuery() {
      return {
        dateRanges: this.totalDaySpan.dayRange.length ? {
          publishTime: {
            startDate: this.totalDaySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.totalDaySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.totalDaySpan.dayRange.length ? undefined : (this.totalDaySpan.dayOffset || 7)
      }
    },
    gapDayQuery() {
      return {
        dateRanges: this.gapDaySpan.dayRange.length ? {
          dateRange: {
            startDate: this.gapDaySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.gapDaySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.gapDaySpan.dayRange.length ? undefined : (this.gapDaySpan.dayOffset || 7)
      }
    }
  },
  methods: {
    disabledDate(current) {
      return current && (current > moment().endOf('day') || current <= moment().add(-31, 'days').endOf('day'))
    },
    onTabChange(activeTab) {
      this.$nextTick(() => this.search())
    },
    queryStatistics() {
      const query = Object.assign(_.cloneDeep(defaultQuery), { authorGroupIds: [this.groupId] }, this.totalDayQuery)
      this.statisticData = {}
      this.$api.core.AwemeService.queryStatistics(query).then(res => {
        this.statisticData = res.data[0] || {}
      })
    },
    findGap() {
      const statQuery = Object.assign(_.cloneDeep(defaultStatQuery), { authorGroupId: this.groupId, orderBys: 'gapDiggCount|desc' }, this.gapDayQuery)
      this.gapStatisticData = {}
      this.$api.core.AwemeService.findGap(statQuery).then(res => {
        this.gapStatisticData = res[0] || {}
      })
    },
    totalRemote(qp) {
      this.queryStatistics()
      return this.$api.core.AwemeService.queryStatisticsFullPaging(qp);
    },
    gapRemote(qp) {
      this.findGap()
      return this.$api.core.AwemeService.queryGapFullPaging(qp);
    },
    search(reset = false) {
      Object.assign(this.totalQuery, { authorGroupIds: [this.groupId], groupBy: 'authorId' }, this.totalDayQuery)
      Object.assign(this.gapQuery, { authorGroupId: this.groupId, groupBy: 'authorId' }, this.gapDayQuery)
      this.$refs[this.activeTab].load({ reset: reset });
    },
    init(options = {}) {
      this.search();
      this.$nextTick(() => {
        this.$refs.qualifiedAuthor.$emit('init')
        this.$refs.awemeComment.$emit('init')
      })
    },
    exportExcel() {
      this.loading = true
      if (this.activeTab==='total'){
        const query = Object.assign(_.cloneDeep(defaultQuery), { authorGroupIds: [this.groupId] , groupBy: 'authorId'}, this.totalDayQuery)
        this.$api.core.AwemeService.exportGrossExcel(query).finally(()=>{
          this.loading = false;
        })
      }else if (this.activeTab==='gap'){
        const statQuery = Object.assign(_.cloneDeep(defaultStatQuery), { authorGroupId: this.groupId , groupBy: 'authorId'}, this.gapDayQuery)
        this.$api.core.AwemeService.exportIncrementExcel(statQuery).finally(()=>{
          this.loading = false;
        })
      }
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
