var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-tabs',{attrs:{"type":"card"},on:{"change":_vm.onTabChange},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('a-tab-pane',{key:"total",attrs:{"tab":"总量"}},[_c('v-table',{ref:"total",attrs:{"columns":_vm.totalColumns,"remote":_vm.totalRemote,"query":_vm.totalQuery,"pagination":_vm.totalPagination,"row-key":"authorId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $rowNum = ref.$rowNum;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$rowNum<3}},[_vm._v(_vm._s($rowNum+1))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('author-card',{attrs:{"value":row,"dataType":_vm.$root.dataType,"small":""}})]}}])})],1),_c('a-tab-pane',{key:"gap",attrs:{"tab":"增量"}},[_c('day-span',{on:{"change":_vm.search},model:{value:(_vm.daySpan),callback:function ($$v) {_vm.daySpan=$$v},expression:"daySpan"}}),_c('v-table',{ref:"gap",attrs:{"columns":_vm.gapColumns,"remote":_vm.gapRemote,"query":_vm.gapQuery,"pagination":_vm.gapPagination,"row-key":"authorId"},scopedSlots:_vm._u([{key:"orderNum",fn:function(ref){
var $rowNum = ref.$rowNum;
return [_c('span',{staticClass:"order-num",attrs:{"le3":$rowNum<3}},[_vm._v(_vm._s($rowNum+1))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('author-card',{attrs:{"value":row.author,"dataType":_vm.$root.dataType,"small":""}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }