<template>
  <div class="video_item">
    <span class="sound" @click="isMuted = !isMuted">
      <a-icon
        type="sound"
        theme="filled"
        :style="{fontSize: '32px', color: isMuted ? '#cccccc' : '#52c41a'}"
      />
    </span>
    <span
      class="close-circle"
      theme="filled"
      @click="removeVideo"
    >
      <a-icon
        type="close-circle"
        theme="filled"
        style="font-size: 22px;color:#cccccc;"
      />
    </span>
    <div ref="danmu" id="vs"></div>
    <div ref="playerBarrage" class="player-barrage-body">
      <!--      <video
              ref="VIDEO"
              :autoplay="autoplay"
              style="width: 284px; height: 412px; border-radius: 6px; border:1px solid #e7e7e7;"
              controls
              :muted="isMuted"
              :loop="loop"
              disablePictureInPicture
              @ended="ended"
              @pause="paused"
            />-->
      <!--      <div :id="playerId" style="width: 200px; height: 400px;"></div>-->
      <EasyPlayer
        :videoUrl="videoUrl"
        @message="$message"
        fluent
        aspect="9:14"
        stretch
        style="width: 270px"
        :live="false"
        :speed="false"
        :show-custom-button="false"
      />
      <span class="video_title">《{{ title ? title : '无标题' }}》</span>
    </div>
    <div class="overlay" v-if="isOverlay">
      <p> 直播结束</p>
    </div>
    <div class="overlay" v-if="isErrorOverlay">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import flvjs from "flv.js";
import api from "@/api/isALive.js";
import Paho from "@/utils/mqttws31.min";
// 文档： https://github.com/bytedance/danmu.js/blob/master/demo/index.html
import DanmuJs from 'danmu.js';
import EasyPlayer from '@easydarwin/easyplayer';

export default {
  name: "Video",
  props: {
    videoUrl: String,
    videoPoster: String,
    autoplay: Boolean,
    muted: Boolean,
    loop: Boolean,
    roomId: String,
    authorId: String,
    title: String
  },
  components: { EasyPlayer },
  watch: {
    muted: {
      handler(to) {
        this.isMuted = to;
      },
      deep: true,
      immediate: true
    },
    videoUrl: {
      handler() {
        // this.$nextTick(this.handleVideo);
        // this.$nextTick(this.handleInitPlayerNode);
      }
      // deep: true,
      // immediate: true
    }
  },
  data() {
    return {
      list: [],
      lastDecodedFrameL: 0,
      isErrorOverlay: false,
      isOverlay: false,
      isMuted: false,
      media: null,
      isPlay: false, // 是否播放
      isEnd: false, // 是否播放完
      isStop: false, // 暂停
      flvPlayer: null,
      text: '推流异常',
      showingBullets: [],
      mqttConfigData: null, // 弹幕配置
      client: null,
      isMqttFlag: false,
      barrage: null,
      danmujs: null,
      playerNode: null
    };
  },
  methods: {
    removeVideo() {
      if (!this.flvPlayer) return;
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
      this.flvPlayer = null;
      this.$emit('remove');
    },
    getMessage() {
      this.danmujs = new DanmuJs({
        // interval: 1500, // 循环间隔
        container: this.$refs.danmu, // 弹幕容器，该容器发生尺寸变化时会自动调整弹幕行为
        /* containerStyle: { //弹幕容器样式
           zIndex: 100
         },*/
        live: true,
        player: this.$refs.playerBarrage, // 配合音视频元素（video或audio）同步使用时需提供该项
        comments: [ // 弹幕预存数组,配合音视频元素（video或audio）同步使用时需提供该项
          /*{
            duration: 20000, //弹幕持续显示时间,毫秒(最低为5000毫秒)
            //moveV: 100, //弹幕匀速移动速度(单位: px/秒)，设置该项时duration无效
            id: '1', //弹幕id，需唯一
            start: 2000, //弹幕出现时间（毫秒）
            prior: true, //该条弹幕优先显示，默认false
            color: true, //该条弹幕为彩色弹幕，默认false
            txt: '长弹幕长弹幕长弹幕长弹幕长弹幕', //弹幕文字内容
            style: {  //弹幕自定义样式
              color: '#ff9500',
              fontSize: '20px',
              padding: '2px 11px'
            },
            mode: 'top' //显示模式，top顶部居中，bottom底部居中，scroll滚动，默认为scroll
            /!* like: { // 点赞相关参数
               el: likeDOM, // el 仅支持传入 dom
               style: { // el 绑定样式
                 paddingLeft: '10px',
                 color: '#ff0000'
               }
             }*!/
            // el: DOM //直接传入一个自定义的DOM元素作为弹幕，使用该项的话会忽略所提供的txt和style
          }*/
        ],
        area: {  //弹幕显示区域
          start: 0, //区域顶部到播放器顶部所占播放器高度的比例
          end: 1 //区域底部到播放器顶部所占播放器高度的比例
        }
        // channelSize: 40, // 轨道大小
        // mouseControl: true, // 打开鼠标控制, 打开后可监听到 bullet_hover 事件。danmu.on('bullet_hover', function (data) {})
        // mouseControlPause: false, // 鼠标触摸暂停。mouseControl: true 生效
        // //bOffset: 1000, //可调节弹幕横向间隔（毫秒）
        // defaultOff: true //开启此项后弹幕不会初始化，默认初始化弹幕
      });
    },
    handleVideo() {
      const { videoUrl, videoPoster } = this;
      const arr = (videoUrl || '').split(".");
      const suffix = (_.nth(arr, -1) || '').toUpperCase();
      let url = this.videoUrl.replace(/^http/, "https");
      const videoElement = this.media || this.$refs.VIDEO || document.getElementById('videoElement');
      if (["FLV", "MP4"].includes(suffix)) {
        if (flvjs.isSupported()) {  //检查flvjs能否正常使用
          this.flvPlayer = flvjs.createPlayer(
            {
              type: _.nth(arr, -1),
              isLive: true,
              // hasAudio: false,
              url: url,
              poster: videoPoster
            },
            {
              fixAudioTimestampGap: false,
              enableStashBuffer: false,
              reuseRedirectedURL: true //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            }
          );
          this.flvPlayer.on("statistics_info", (res) => {
            if (this.lastDecodedFrame == 0) {
              this.lastDecodedFrame = res.decodedFrames;
              return;
            }
            if (this.lastDecodedFrame != res.decodedFrames) {
              this.lastDecodedFrame = res.decodedFrames;
              // console.log('22');
            } else {
              // console.log('11');
              this.lastDecodedFrame = 0;
              if (this.flvPlayer) {
                if (this.isStop) {
                  this.flvPlayer.pause();
                } else {
                  this.flvPlayer.pause();
                  this.flvPlayer.unload();
                  this.flvPlayer.detachMediaElement();
                  this.flvPlayer.destroy();
                  this.flvPlayer = null;
                  this.handleVideo(this.flvPlayer);
                }
              }
            }
          }); // 没有错误信息，画面卡住或者loading 销毁重连
          this.flvPlayer.on('error', (errorType, errorDetail, errorInfo) => {
            // console.log('网络波动,正在尝试连接中...');
            // console.log('errorType', errorType);
            // console.log('errorDetail', errorDetail);
            // console.log('errorInfo', errorInfo);
            if (this.flvPlayer) {
              this.flvPlayer.pause();
              this.flvPlayer.unload();
              this.flvPlayer.detachMediaElement();
              this.flvPlayer.destroy();
              this.flvPlayer = null;
              this.handleVideo(this.flvPlayer);
            }
            this.isPlay = false;
            this.isEnd = true;
            this.flvPlayer = null;
            this.getLivingStatus();
          }); //判断连接是否已经断开,继而进行断流重连
          this.flvPlayer.attachMediaElement(videoElement); // 装进video元素里
          if (url !== "" && url !== null) {
            this.flvPlayer.load(); //载入视频
            this.flvPlayer.play();//播放视频，如果不想要自动播放，去掉本行
          }
        }
      } else {
        videoElement.src = videoUrl;
        videoElement.poster = videoPoster;
      }
      if (!this.danmujs && !this.client) {
        this.addLiveRoom();
        this.barrageConfig();
        this.$nextTick(this.getMessage);
      }
    },
    paused() {
      // console.log('暂停');
      this.isStop = true;
    },
    ended() {
      this.isPlay = false;
      this.isEnd = true;
      this.isOverlay = true;
    },
    addLiveRoom() {
      api.addLiveRoom({ platform_type: 1, room_id: this.roomId }).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.data);
        }
      });
    },
    // 查询直播状态
    getLivingStatus() {
      // console.log('查询直播状态');
      api.livingStatus({
        room_id: this.roomId,
        author_id: this.authorId
      }).then((res) => {
        if (res.code == 0) {
          if (res.data == 2) {
            this.text = '推流异常';
          } else if (res.data == 4) {
            this.text = '直播已结束';
          }
        }
      });
    },
    // 获取弹幕配置
    barrageConfig() {
      api.getBarrage({ platform_type: 1, author_id: this.authorId, device_id: +new Date() }).then(res => {
        const { data } = res;
        if (data.code == 0) {
          this.mqttConfigData = data.data;
          // console.log(this.mqttConfigData);
          this.MQTTconnect();
          // this.removal = new MqttRemoval();
        }
      });
    },
    MQTTconnect() {
      const { endPoint, clientId, userName, password } = this.mqttConfigData;
      this.client = new Paho.MQTT.Client(endPoint, 443, clientId);
      this.client.connect({
        userName, //连接帐号
        password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          // console.log("message", message);
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },
    //当客户端连接
    onConnect() {
      //建立连接后，进行订阅并发送消息。
      // console.log("onConnect", this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic);
      this.client.subscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );

      // this.client.subscribe(
      //     'afanti_live/bulletAndStatistics/1/1604919861718830',
      //     { qos: 0 }
      // );
      // this.client.subscribe(
      //     'afanti_live/bulletAndStatistics/1/63096530023',
      //     { qos: 0 }
      // );

      // var message = new Paho.MQTT.Message("Hello");
      // message.destinationName = "World";
      // message.qos = 0;
      // this.client.send(message);
    },
    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        // console.log("onConnectionLost:" + responseObject.errorMessage);
        console.error(responseObject);
      }
    },
    //当消息到达时
    onMessageArrived(message) {
      const bulletAndStatisticsTopic = this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic;
      const index = bulletAndStatisticsTopic.lastIndexOf("\/");
      const url = bulletAndStatisticsTopic.substring(index + 1, bulletAndStatisticsTopic.length);
      const newJson = JSON.parse(message.payloadString);
      if (newJson.type == '1001' && this.authorId == url) {
        // this.list.push(newJson.data);
        // console.log(newJson, '弹幕信息');
        const item = newJson.data;
        const key = _.uniqueId(`fctc_${ this._uid }_`);
        this.danmujs.sendComment({  //发送弹幕
          duration: 4500, // 动画时间
          id: key,
          // start: 3000, //不提供该项则立即发送
          txt: `${ item.userName }: ${ item.content }`,
          style: {
            color: 'yellow',
            fontSize: '16px'
          }
        });
      }
    },
    /*  // 初始化播放组件
      handleInitPlayerNode() {
        // this.playerNode.play(this.videoUrl, 1);
      },
      // 视频播放回调
      handlePlayerCallback(evt) {
        console.log(evt);
      },*/
    message(evt) {
      // console.log(evt);
    }
  },
  mounted() {
    // this.playerNode = new WasmPlayer(
    //   null,
    //   this.playerId,
    //   this.handlePlayerCallback,
    //   {
    //     cbUserPtr: this, // 自定义指针(this的指向)
    //     // cfKbs: fn, // 码率速率回调(averageKbps:平均传输速率，averageKbs: 平均码率，currentKbps: 当前传输速率，currentKbs: 当前码率)
    //     decodeType: "auto", // 解码类型(auto：默认，soft：强制H265解码)
    //     openAudio: true, // 是否打开音频
    //     // BigPlay: true, // 是否开启大的播放按钮
    //     Height: true, // 播放器尺寸是否继承父盒子的
    //     HideKbs: true // 是否隐藏实时码率
    //   }
    // );
  },
  computed: {
    playerId() {
      return `PlayerNode_${ this._uid }`;
    }
  },
  beforeDestroy() {
    if (this.client) {
      this.client.unsubscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.disconnect(); // 断开连接
      this.client = null;
    }
    if (!this.flvPlayer) return;
    this.flvPlayer.pause();
    this.flvPlayer.unload();
    this.flvPlayer.detachMediaElement();
    this.flvPlayer.destroy();
    this.flvPlayer = null;
  }
};
</script>

<style scoped lang="less">
.video_item {
  padding: 0 20px;
  position: relative;

  .play-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .sound {
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 999;
  }

  .close-circle {
    position: absolute;
    top: -10px;
    right: 32px;
    z-index: 999;
    cursor: pointer;
  }
}

#vs {
  position: absolute;
  left: 20px;
  width: 284px;
  height: 412px;
  z-index: 9999;
  pointer-events: none;
}

.player-barrage-body /deep/ .vjs-control-bar {
  display: none !important;
}

.overlay {
  width: 283px;
  height: 412px;
  border-radius: 6px;
  margin: 0 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  cursor: pointer;

  p {
    font-size: 32px;
    color: #fff;
    text-align: center;
    line-height: 372px;
  }
}

//进度条
video::-webkit-media-controls-timeline {
  display: none;
}

.video_title {
  position: absolute;
  width: 100%;
  bottom: -30px;
  left: 0px;
  font-size: 20px;
  font-weight: bold;
}
</style>
