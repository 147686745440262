<template>
  <div>
    <a-page-header title="评论归集" style="margin: 0 -24px 0; border-bottom: 1px solid #ebedf0;" />
    <a-row type="flex" style="padding: 16px 0">
      <a-col flex="auto">
        <a-form-model layout="inline">
          <a-form-model-item label="关键字">
            <a-input allowClear v-model="query.param.content" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" html-type="submit" @click="search">搜索</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col>
        <div class="tools">
          <a-button type="primary" icon="export" @click="exportExcel" :loading="loading">导出</a-button>
        </div>
      </a-col>
    </a-row>
    <v-table ref="table" :columns="columns" :remote="remote" :query="query" :pagination="pagination" @loaded="onLoad" row-key="msgId">
      <template #code="{row}">
        <author-card :value="row.fans || {}" :dataType="$root.dataType" small :link="false"></author-card>
      </template>
      <template #content="{row}">
        <v-text :value="row.content" :keyword="query.param.content"></v-text>
      </template>
    </v-table>
  </div>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}
</style>
<script>

import AuthorCard from "@/components/widgets/author-card"

const defaultQuery = {
  orderBys: "publishTime|desc,roomId|desc",
  param: {
    content: '',
    method: 'WebcastChatMessage'
  },
  groupId: undefined,
  start: 0,
  limit: 20
}
export default {
  components: { AuthorCard },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      orderDateRange: [],
      query: _.cloneDeep(defaultQuery),
      loading: false,
      pagination: { pageSize: 10 },
      visible: false,
    }
  },
  computed: {
    columns() {
      return [
        { title: "评论人", dataIndex: "code", scopedSlots: { customRender: 'code' }, width: 200 },
        { title: "评论内容", dataIndex: "content", scopedSlots: { customRender: 'content' }, width: 200 },
        { title: "评论时间", dataIndex: "publishTime", width: 80 }
      ]
    }
  },
  methods: {
    onLoad(page) {
      page.data.forEach((t, i)=> {
        !t.user && (t.user = {})
        t.fans = {
          nickname: t.user.nickname,
          avatar: t.user.avatar,
          code: t.user.code
        }
        t.msgId = t.msgId || i
      })
    },
    clear() {
      this.query = _.cloneDeep(defaultQuery);
      this.$nextTick(() => {
        this.search(true);
      });
    },
    init(options = {}) {
      this.search();
    },
    remote(qp) {
      return this.$api.core.LiveService.queryComment(qp);
    },
    exportExcel(){
      this.loading = true
      this.search()
      this.$api.core.LiveService.exportReviewExcel(this.query).finally(()=>{
        this.loading = false
      });
    },
    search(reset = false) {
      Object.assign(this.query, { groupId: this.groupId })
      this.$refs.table.load({ reset: reset });
    }
  },
  created() {},
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
