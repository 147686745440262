<template>
  <a-list-item :small="small" :favorite="favorite" :column="column">
    <a-list-item-meta>
      <template #avatar>
        <a-avatar :size="small ? 42 : 70" icon="user" :src="wrapValue.avatar"/>
        <a-button v-if="favorite" type="link" @click="$emit('favorite', value)">收藏播主</a-button>
      </template>
      <template #title>
        <!-- <router-link v-if="link && wrapValue.authorId" class="author-card-title" :to="{ name: 'author:analysis', params: { authorId: wrapValue.authorId } }"
                     :title="wrapValue.nickname || '-'" target="_blank">{{ wrapValue.nickname || '-' }}</router-link> -->
        <span class="author-card-title" :title="wrapValue.nickname || '-'">{{ wrapValue.nickname || '-' }}</span>
      </template>
      <div slot="description" class="author-card-desc">
        <span v-if="wrapValue.code">{{ `${$dict.getText(dataType,$dict.store.DATATYPE)}号：${wrapValue.code || '-'}` }}</span>
        <div class="author-card-tags" v-if="!small">
          <a-tag v-if="wrapValue.manufactorName" :title="wrapValue.manufactorName">{{wrapValue.manufactorName}}</a-tag>  <!--主机厂名称-->
          <a-tag v-if="wrapValue.dealerName" :title="wrapValue.dealerName">{{wrapValue.dealerName}}</a-tag>          <!--经销商名称-->
        </div>
      </div>
    </a-list-item-meta>
  </a-list-item>
</template>

<script>

  export default {
    name: "author-card",
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      dataType: {
        type: Number,
        default: 1
      },
      small: {
        type: Boolean,
        default: false
      },
      favorite: {
        type: Boolean,
        default: false
      },
      link: {
        type: Boolean,
        default: true
      },
      column: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({

    }),
    computed: {
      wrapValue() {
        return this.value || {}
      }
    },
    methods: {
/*      analysis() {
        this.$router.push({ name: 'author:analysis', params: { authorId: this.value.authorId } })
      }*/
    }
  }
</script>

<style scoped>
.ant-list-item {
  display: block;
}
.ant-list-item-meta-content {
  overflow: hidden;
}
.ant-list-item[small] {
  padding: 6px 0;
}
[column] .ant-list-item-meta {
  flex-direction: column;
}
[column] .ant-list-item-meta .ant-list-item-meta-avatar {
  margin: 0 auto 5px;
}
a.author-card-title:hover {
  color: #ff9900;
  cursor: pointer;
}
.author-card-desc {
  display: flex;
  color: #000;
  flex-flow: column;
}
.ant-list-item-meta-avatar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.ant-list-item-meta-avatar .ant-btn {
  margin-top: 10px;
}
.ant-list-item-meta-title, .author-card-desc > span {
  /*max-width: 180px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-list-item[favorite] .ant-list-item-meta-title {
  max-width: 300px;
  font-size: 1.1rem;
  color: #000;
  font-weight: bold;
}
.ant-list-item[favorite] .author-card-desc, .ant-list-item[favorite] .author-card-tags {
  margin-top: 10px;
}
.author-card-tags {
  display: flex;
  margin-top: 5px;
}
.ant-tag {
  /*max-width: 200px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>