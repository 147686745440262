<template>
  <a-spin :spinning="loading">
    <a-row class="group-authors-row" type="flex">
      <a-empty v-show="authors.length<1" style="margin:0 auto;"/>
      <a-card v-for="item in authors" :key="item.authorId" :loading="loading" @click="analysis(item)" :bordered="false">
        <a-card-grid>
          <a-avatar :size="35" :src="item.avatar" />
          <span class="author-name">{{ item.nickname || '-'}}</span>
        </a-card-grid>
      </a-card>
    </a-row>
  </a-spin>
</template>

<script>
let defaultQuery = {
  orderBys: "ctime|desc,id|desc",
  param: {
    groupId: ""
  },
  start: 0,
  limit: 1000
}

  export default {
    name: "group-authors",
    props: {
      groupId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loading: false,
      query: _.cloneDeep(defaultQuery),
      authors: []
    }),
    methods: {
      load() {
        Object.assign(this.query.param, { groupId: this.groupId })
        this.loading = true
        this.$api.FavoriteService.findFavoriteBiz(1, this.query).then(res => {
          this.authors = res.data
          this.$emit('loaded', res.data)
        }).finally(() => this.loading = false)
      },
      analysis(author) {
        this.$router.push({ name: 'author:analysis', params: { authorId: author.authorId } })
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style scoped>
.group-authors-row {
  min-height: 108px;
  max-height: 210px;
  overflow-y: auto;
  padding: 5px;
}
.ant-card-grid {
  width: auto;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  cursor: pointer;
  padding: 12px;
}
.ant-card-grid:not(:hover) {
  border: unset; box-shadow: unset;
}
.group-authors-row .author-name {
  margin-top: 10px;
  text-align: center;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>