<!--表单-->
<template>
  <div>
    <day-span v-model="daySpan" @change="search"></day-span>
    <a-row type="flex" style="padding: 16px 0">
      <a-col flex="auto">
        <v-statistics :columns="statisticColumns" :data="statisticData"></v-statistics>
      </a-col>
      <a-col>
        <div class="tools">
          <a-button type="primary" icon="export" @click="exportExcel" :loading="loading">导出</a-button>
        </div>
      </a-col>
    </a-row>
    <v-table ref="table" :columns="columns" :remote="remote" :query="query" :pagination="pagination" :scroll="{ x: 800 }" row-key="authorId">
      <template #orderNum="{$rowNum}">
        <span class="order-num" :le3="$rowNum<3">{{$rowNum+1}}</span>
      </template>
      <template #code="{row}">
        <author-card :value="row.author" :dataType="$root.dataType" small style="max-width: 244px"></author-card>
      </template>
    </v-table>
    <live-comment ref="liveComment" :group-id="groupId"></live-comment>
  </div>
</template>
<style scoped>
</style>
<script>
import DaySpan from "@/components/widgets/day-span"
import AuthorCard from "@/components/widgets/author-card"
import LiveComment from "./LiveComment"

const defaultQuery = {
  orderBys: 'liveCount|desc,authorId|desc',
  dayOffset: undefined,
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  param: {
  },
  groupIds: undefined,
  groupBy: undefined
}

export default {
  components: { DaySpan, AuthorCard, LiveComment },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      query: _.cloneDeep(defaultQuery),
      loading: false,
      pagination: { pageSize: 5 },
      daySpan: {
        dayOffset: 7,
        dayRange: []
      },
      statisticColumns: [
        { title: '直播总场次', dataIndex: 'liveCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '直播总时长', dataIndex: 'liveTime', suffix: 'min', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '场均时长', dataIndex: 'avgLiveTime', suffix: 'min', formatter: ({ value }) => this.$dict.humanReadable(value, 1) },
        { title: '总累计观众', dataIndex: 'totalWatchUserCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '总点赞量', dataIndex: 'likeCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '总评论量', dataIndex: 'commentCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        // { title: '总转发量', dataIndex: 'shareCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '送礼总人数', dataIndex: 'giftUserCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: `送礼总收入(${this.$dict.getText(this.$root.dataType, this.$dict.store.TICKET_LABEL)})`, dataIndex: 'ticketCount', formatter: ({ value }) => this.$dict.humanReadable(value) },
      ],
      statisticData: {}
    }
  },
  computed: {
    columns() {
      return [
        { title: '排序', dataIndex: 'orderNum', scopedSlots: { customRender: 'orderNum' }, fixed: 'left', width: 45 },
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: 'code', scopedSlots: { customRender: 'code' }, fixed: 'left', ellipsis: true, width: 260 },
        { title: '场次', dataIndex: 'liveCount', sorter: true, defaultSortOrder: 'descend', customRender: this.$dict.humanReadable, width: 80 },
        { title: '频次(天/次)', dataIndex: 'frequency', width: 80 },
        { title: '总时长(min)', dataIndex: 'liveTime', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '场均时长(min)', dataIndex: 'avgLiveTime', sorter: true, customRender: v => this.$dict.humanReadable(v, 1), width: 80 },
        { title: '累计观众', dataIndex: 'totalWatchUserCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '场均观众', dataIndex: 'avgWatchUserCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '点赞量', dataIndex: 'likeCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '评论量', dataIndex: 'commentCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        // { title: '转发量', dataIndex: 'shareCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: '送礼人数', dataIndex: 'giftUserCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 },
        { title: `送礼收入(${this.$dict.getText(this.$root.dataType, this.$dict.store.TICKET_LABEL)})`, dataIndex: 'ticketCount', sorter: true, customRender: this.$dict.humanReadable, width: 80 }
      ]
    },
    dayQuery() {
      return {
        dateRanges: this.daySpan.dayRange.length ? {
          dateRange: {
            startDate: this.daySpan.dayRange[0].format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.daySpan.dayRange[1].format('YYYY-MM-DD') + ' 23:59:59'
          }
        } : undefined,
        dayOffset: this.daySpan.dayRange.length ? undefined : (this.daySpan.dayOffset || 7)
      }
    }
  },
  methods: {
    queryStatistics() {
      const statQuery = Object.assign(_.cloneDeep(defaultQuery), { orderBys: undefined, groupIds: [this.groupId] }, this.dayQuery)
      this.statisticData = {}
      this.$api.core.LiveService.queryStatistics(statQuery).then(res => {
        this.statisticData = res.data[0] || {}
      })
    },
    remote(qp) {
      return this.$api.core.LiveService.queryStatisticsFullPaging(qp);
    },
    search(reset = false) {
      Object.assign(this.query, { groupIds: [this.groupId], groupBy: 'authorId' }, this.dayQuery)
      this.queryStatistics()
      this.$refs.table.load({ reset: reset });
    },
    exportExcel(){
      this.loading = true;
      const exportQuery = Object.assign(_.cloneDeep(defaultQuery), { groupIds: [this.groupId], groupBy: 'authorId' }, this.dayQuery)
      this.$api.core.LiveService.exportExcel(exportQuery).finally(()=>{
        this.loading = false
      });
    },
    init(options = {}) {
      this.search();
      this.$nextTick(() => {
        this.$refs.liveComment.$emit('init')
      })
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
